import React from "react";
import styles from "./pages.module.css";

import { Footer } from "../comonents/Footer/Footer";
import { TagSelector } from "../comonents/TagSelector/TagSelector";

export default function Tag () {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {/* <TagBanner /> */}
                <TagSelector />
                
            </div>
            <Footer />
        </div>
    );
}