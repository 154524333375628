import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import styles from "./CompactBook.module.css";

import Markdown from 'react-markdown';
import Clamp from 'react-multiline-clamp';

import { Tag } from "../../Tag/Tag";
import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";


export const CompactBook = (props) => {

    // props: 
    //
    // mediaItem: book object

    // ----------------------------------------------------- STATE AND HOOK

    const [isLoaded, setIsLoaded] = useState(false);

    const [formattedFull, setformattedFull] = useState("");

    const [firstWordsOfTitle, setFirstWordsOfTitle] = useState("");
    const [lastWordOfTitle, setLastWordOfTitle] = useState("");

    const seriesPath = "/series/" + props.mediaItem.series?.id;
    const bookPath = `/${props.mediaItem.type}/` + props.mediaItem.mediaID;

    // useEffect(() => {
    //     if (props.mediaItem && props.mediaItem.review) {
    //         setformattedFull(formatReview(props.mediaItem.review));
    //     }
    // }, [props.mediaItem.review])

    useEffect(() => {
        // sets favorited when mediaItem arrives
        if (props.mediaItem) {
            // processTitle(); 

            // setformattedFull();
            processBookInfo();
        }

    }, [props.mediaItem]);

    // ----------------------------------------------------- PRE-RENDER
    var tags = [];

    if (props.mediaItem && props.mediaItem.tags) { //checks that mediaItem has propegated before accessing

        if (window.screen.width > 500) {
            // DESKTOP
            if (props.mediaItem.tags.length > 4) { // checks number of tags
                for (let i = 0; i < 4; i++) { // only renders first 4
                    tags.push(<div key={props.mediaItem.mediaID + "." + props.mediaItem.tags[i].tagID}><Tag tagItem={props.mediaItem.tags[i]} /></div>)
                }
            } else if (props.mediaItem.tags.length > 0) { // renders all when less than 4 total
                tags = props.mediaItem.tags.map((tag) =>
                    <div key={props.mediaItem.mediaID + "." + tag.tagID} ><Tag tagItem={tag} /></div>
                )
            }
        } else {
            // MOBILE
            if (props.mediaItem.tags.length > 2) { // checks number of tags
                for (let i = 0; i < 2; i++) { // only renders first 2
                    tags.push(<div key={props.mediaItem.mediaID + "." + props.mediaItem.tags[i].tagID}><Tag tagItem={props.mediaItem.tags[i]} /></div>)
                }
            } else if (props.mediaItem.tags.length > 0){ // renders all when less than 2 total
                tags = props.mediaItem.tags.map((tag) =>
                    <div key={props.mediaItem.mediaID + "." + tag.tagID} ><Tag tagItem={tag} /></div>
                )
            }
        }

    }

    function formatReview(review) {
        //adds white space to descritpion

        if (review === null) {
            // no review
            return null;
        }

        var formattedReview = [];
        let index = 0;

        let reviewPeices = review.split('\n'); //splits review string by newlines
        for (const element of reviewPeices) {
            index++;
            if (!element) { //if empty it means there was a blank line
                formattedReview.push(<div className={styles.spacer} key={index}></div>)
            } else {
                formattedReview.push(<Markdown key={index}>{element}</Markdown>) //adds string segment to array
            }
        }
        setformattedFull(formattedReview);
        return null;
    }

    function processTitle() {
        //splits title into two peices and adds star to second to prevent weird line wrapping

        let words = props.mediaItem.title.split(" ");
        let lastWord = words.pop();

        const firstWords = words.join(" ") + " "
        if (props.mediaItem.favorited) {
            lastWord = [lastWord, <img key="star" className={styles.starIconFilled} alt="Favorite star" />];
        } else {
            lastWord = [lastWord];
        }

        setFirstWordsOfTitle(firstWords);
        setLastWordOfTitle(lastWord);

        return null;
    }

    async function processBookInfo() {
        // gets formatted review and title

        const [] = await Promise.all([
            processTitle(),
            formatReview(props.mediaItem.review)
        ]);
        setIsLoaded(true);
    }


    // ----------------------------------------------------- RENDER 
    if (!isLoaded) {
        return (null);
    } else {
        if (window.screen.width > 500) {
            // DESKTOP
            return (
                <div className={styles.compactBook} >
                    <Cover mediaItem={props.mediaItem} size="M" loc="compactBook" />
                    <div>
                        <div className={styles.infoAndTags}>
                            <div className={styles.titleAndSeriesAndAuthor}>
                                <Link to={bookPath} className={styles.link}>
                                    <div className={styles.bookTitle}>
                                        <span className={styles.firstWordsOfTitle}>{firstWordsOfTitle}</span>
                                        <span className={styles.lastWordOfTitle}>{lastWordOfTitle}</span>
                                    </div>
                                </Link>
                                {props.mediaItem.series != null ?
                                    <div>
                                        <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{props.mediaItem.series?.name} #{props.mediaItem.seriesNum}</h3></Link>
                                    </div> :
                                    null
                                }
                                <Author authorsList={props.mediaItem.creators} size={1} link={true} />
                            </div>
                            <div className={styles.tags}>{tags}</div>
                        </div>
                        <div>
                            <Clamp lines={1}
                                withToggle={true}
                                maxLines={100}
                                withTooltip={false}
                                showMoreElement={({ toggle }) => (
                                    <button className={styles.readMoreOrLessButton}
                                        onClick={toggle} >
                                        <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                                    </button>
                                )}
                                showLessElement={({ toggle }) => (
                                    <button className={styles.readMoreOrLessButton}
                                        onClick={toggle} >
                                        <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                                    </button>
                                )}>
                                <div className={styles.bookReview}>{formattedFull} </div>
                            </Clamp>

                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <section className={styles.mobileContainer}>
                    <div className={styles.mobileContent}>

                        <div className={styles.mobileBookInfo}>
                            <Cover mediaItem={props.mediaItem} size="M" loc="compactBook" />
                            <div className={styles.mobileInfo}>
                                <div className={styles.titleAndSeriesAndAuthor}>
                                    <Link to={bookPath} className={styles.link}>
                                        <div className={styles.bookTitle}>
                                            <span className={styles.firstWordsOfTitle}>{firstWordsOfTitle}</span>
                                            <span className={styles.lastWordOfTitle}>{lastWordOfTitle}</span>
                                        </div>
                                    </Link>
                                    {props.mediaItem.series != null ?
                                        <div>
                                            <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{props.mediaItem.series?.name} #{props.mediaItem.seriesNum}</h3></Link>
                                        </div> :
                                        null
                                    }
                                    <Author authorsList={props.mediaItem.authors} size={1} link={true} />
                                </div>
                            </div>
                        </div>

                        <div className={styles.mobileUserInfo}>
                            <div className={styles.tags}>{tags}</div>
                            <div className={styles.review}>
                                <Clamp lines={1}
                                    withToggle={true}
                                    maxLines={100}
                                    withTooltip={false}
                                    showMoreElement={({ toggle }) => (
                                        <button className={styles.readMoreOrLessButton}
                                            onClick={toggle} >
                                            <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                                        </button>
                                    )}
                                    showLessElement={({ toggle }) => (
                                        <button className={styles.readMoreOrLessButton}
                                            onClick={toggle} >
                                            <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                                        </button>
                                    )}>
                                    <div className={styles.bookReview}>{formattedFull} </div>
                                </Clamp>

                            </div>
                        </div>
                    </div>
                </section>
            );
        }
    }
}