import React, { useEffect } from "react";
import styles from "./BugReport.module.css";

import { Link, useNavigate } from "react-router-dom";

import Textarea from 'rc-textarea';
import emailjs from 'emailjs-com';
import ScrollToTop from "../../helpers/ScrollToTop";

export const BugReportForm = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let navigate = useNavigate();
    let token = localStorage.getItem("token");
    
    // getting userID
    let tokenPeices = token.split(".");
    let idAndRole = atob(tokenPeices[1]);
    let userID = idAndRole.split(",")[0];

    useEffect(() =>{
        ScrollToTop();
    },[])

    // ----------------------------------------------------- PRE-RENDER

    function sendEamil(e) {
        e.preventDefault();

        emailjs.sendForm(
            'earmarked',
            'template_bug_report',
            e.target,
            `zzKVq_gPiaT31H8J4`
        )
            .then(() => {
                navigate(-1);
            })
            .catch((error) => {
                console.log("failed to send bug report: ", error)
            })
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <form className={styles.bugForm} onSubmit={sendEamil}>

                <div className={styles.inputLableGroup}>
                    <input type="hidden" name="userID" className={styles.input} value={userID}/>
                </div>


                <div className={styles.inputLableGroup}>
                    <label className={styles.label}>Bug Title</label>
                    <input type="text" name="bug_title" autoComplete="off" className={styles.input} />
                </div>

                <div className={styles.inputLableGroup}>
                    <label className={styles.label}>Circumstances</label>
                    <div className={styles.subtitle}>
                        What where you doing when you experianced the bug? Have you been able to repeat it?
                    </div>
                    <Textarea type="text" name="bug_circumstances" autoComplete="off" autoSize={{ minRows: 5 }} className={styles.input} />
                </div>

                <div className={styles.inputLableGroup}>
                    <label className={styles.label}>Bug</label>
                    <div className={styles.subtitle}>
                        Please be as detailed as possible
                    </div>
                    <Textarea type="text" name="bug_content" autoComplete="off" autoSize={{ minRows: 5 }} className={styles.input} />
                </div>

                <input type="submit" value="Send" className={styles.submitButton}/>
            </form>

            <div className={styles.label}>OR</div>
            <Link target={"_blank"} to="https://github.com/capybeby/earmarked/issues" className={styles.link}><div className={styles.github}>File on GitHub</div><img src="/assets/images/github.png" className={styles.githubLogo}/></Link>
        </section>
    )
}