import React, { useEffect, useState } from "react";
import styles from "./pages.module.css";
import { TVShowBanner } from "../comonents/Banners/TVShowBanner";
import { useParams } from "react-router-dom";

import { Footer } from "../comonents/Footer/Footer";

import { useMedia, useMediaUpdate } from "../contexts/MediaContext";
import { getMediaByID } from "../api/Api";

export default function TVShow () {

    // ----------------------------------------------------- STATE AND HOOKS
    const tvshowID = useParams().tvshowID;
    let token = localStorage.getItem("token");

    const mediaUpdater = useMediaUpdate();
    const medaiItem = useMedia();

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => { 
        // checks to see if tvshow data has loaded yet
        if (Object.keys(medaiItem).length !== 0) {
            setIsLoaded(true);
        }
    }, [medaiItem]);

    useEffect(() => {
        // gets tvshow data once params come through
        fetchTVShow();
    },[tvshowID])
    // ----------------------------------------------------- PRE-RENDER
    

    async function fetchTVShow() {
        await getMediaByID(tvshowID, token)
            .then((foundTVShow) => {
                mediaUpdater(foundTVShow);
            })
            .catch((error) => {
                console.log(error, "failed to fetch tv show");
            })
    }

    // ----------------------------------------------------- RENDER

    if (!isLoaded) {
        return (
            <></>
        )
    } else {
        return (
            <section className={styles.container}>
                <TVShowBanner />
                <Footer />
            </section>
        )
    }
    
}