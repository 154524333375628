import React from "react";
import styles from "./TBRBook.module.css";

import { Cover } from "../../Cover/Cover";

export const TBRbook = (props) => {

    // props:
    //
    // mediaItem - all book data

    // ----------------------------------------------------- PRE-RENDER

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            <Cover mediaItem = {props.mediaItem} size = {window.screen.width > 500 ? "L" : "M"} loc="TBRBook"/>
        </section>
    );
}