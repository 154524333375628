import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom"
import styles from './NavigationBar.module.css'
import { Search } from "../Search/Search";
import { useMediaUpdate } from "../../contexts/MediaContext";
import { ProfileDropdown } from "../ProfileDropdown/ProfileDropdown";

export const NavigationBar = () => {

    // ----------------------------------------------------- STATES AND HOOKS

    const mediaUpdater = useMediaUpdate();

    const [links, setLinks] = useState();

    const [isHambburgerOpen, setIsHambergerOpen] = useState(false);
    const [isSearchOpen, setIsSeatchOpen] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);

    let token = localStorage.getItem("token");
    var tokenPeices = [];
    var decodedUserID = [];

    const navigate = useNavigate();
    
    if (token != null) {
        tokenPeices = token.split(".");
        decodedUserID = atob(tokenPeices[1]);
    }

    useEffect(() => {
        setIsHambergerOpen(false);
        setIsSeatchOpen(false);
        setIsProfileOpen(false);
        
        makeLinks(window.location.href); // makes links for mobile and desktop

    }, [window.location.href])

    const makeLinks = (newLink) => {
        // makes link objects 

        if (window.screen.width < 500 ) {
            // MOBILE
            setLinks(
                <div className={styles.mobileNavLinks}>
                    <Link to="/" className={styles.activeNavbarLink}>Home</Link>
                    <Link to="/library?page=1&library=read" className={styles.activeNavbarLink}>Library</Link>
                    <Link to='/tags' className={styles.activeNavbarLink}>Tags</Link>
                    <Link to='/catalog' className={styles.activeNavbarLink}>Catalog</Link>
                </div>
            )
        } else {
            // DESKTOP 
            setLinks(
                <div className={window.screen.width > 500 ? styles.navItems : styles.mobileNavLinks}>
                    <Link to="/" className={newLink === 'https://earmarked.app/' ? styles.activeNavbarLink : styles.navbarLink}>Home</Link>
                    <Link to="/library?page=1&library=read" className={newLink.includes('https://earmarked.app/library')  ? styles.activeNavbarLink : styles.navbarLink}>Library</Link>
                    <Link to='/tags' className={newLink.includes('https://earmarked.app/tags')  ? styles.activeNavbarLink : styles.navbarLink}>Tags</Link>
                    <Link to='/catalog?page=1' className={newLink.includes('https://earmarked.app/catalog')  ? styles.activeNavbarLink : styles.navbarLink}>Catalog</Link>
                </div>
            )
        }

    }

    const toggleIsHambergerOpen = () => {
        // toggles visiability of nav overlay on moble
        setIsHambergerOpen(!isHambburgerOpen);

        if (!isHambburgerOpen) {
            setIsProfileOpen(false);
            setIsSeatchOpen(false);
        }
    }

    const toggleIsSearchOpen = () => {
        // toogles visabilty of search bar
        setIsSeatchOpen(!isSearchOpen);

        if (!isSearchOpen) {
            setIsHambergerOpen(false);
            setIsProfileOpen(false);
        }
    }

    const toggleIsProfileOpen = () => {
        // toggles visiablity of nav options
        setIsProfileOpen(!isProfileOpen);

        if (!isProfileOpen) {
            setIsHambergerOpen(false);
            setIsSeatchOpen(false);
        }
    }

    function signOut() {
        // clears token
        localStorage.removeItem("token");
        navigate("/")
        window.location.reload();
    }

    // ----------------------------------------------------- RENDER
    if (window.screen.width > 500) {

        // ------------------------------------- DESKTOP 
        return (
            <nav className={styles.container} id="nav">
                <div className={styles.content}>
                    <Link to="/" className={styles.logoContainer}> <img src="/assets/images/logo.png" className={styles.logo} alt="logo"></img></Link>
                    <div className={styles.linksAndSearch}>
                        {links}
                        <div className={styles.searchBar}><Search /></div>
                    </div>
                    <div className={styles.profile}><ProfileDropdown /></div>
                </div>
            </nav>

        );

        // ------------------------------------- MOBILE

    } else {
        return (
            <nav className={styles.container} id="nav">
                <div className={styles.content}>
                    <Link to="/" className={styles.logoContainer}> <img src="/assets/images/logo.png" className={styles.logo} alt="logo"></img></Link>
                    <div className={styles.mobileTopRightIcons}>
                        <img src="/assets/images/searchIcon.svg" className={styles.searchIcon} onClick={toggleIsSearchOpen} alt="search icon"/>
                        <img src="/assets/images/profile.svg" className={styles.profileIcon} onClick={toggleIsProfileOpen} alt="profile icon"/>
                        <img src="/assets/images/hamburger.svg" className={styles.hamburger} onClick={toggleIsHambergerOpen} alt="hamberger menu"/>
                    </div>
                </div>

                {/* // ------------------------------------- HAMBERGER  */}

                {isHambburgerOpen ?
                    <div className={styles.overlay} onClick={toggleIsHambergerOpen}>
                        <div className={styles.content}>
                            <Link to="/" className={styles.logoContainer}> <img src="/assets/images/logo.png" className={styles.logo} ></img></Link>
                            <div className={styles.mobileTopRightIcons}>
                                <img src="/assets/images/searchIcon.svg" className={styles.searchIcon} onClick={toggleIsSearchOpen} alt="search icon"/>
                                <img src="/assets/images/profile.svg" className={styles.profileIcon} onClick={toggleIsProfileOpen} alt="profile icon"/>
                                <img src="/assets/images/hamburgerDark.svg" className={styles.hamburger} onClick={toggleIsHambergerOpen} alt="hamberger menu"/>
                            </div>
                        </div>
                        <div className={styles.hambergerOverlayContent}>
                            {links}
                        </div>
                    </div>
                    :
                    null
                }

                {/* // ------------------------------------- SEARCH */ }
                
                {isSearchOpen ?
                    <div className={styles.overlay}>
                        <div className={styles.content} onClick={toggleIsSearchOpen}>
                            <Link to="/" className={styles.logoContainer}> <img src="/assets/images/logo.png" className={styles.logo} ></img></Link>
                            <div className={styles.mobileTopRightIcons}>
                                <img src="/assets/images/searchIconDark.svg" className={styles.searchIcon} onClick={toggleIsSearchOpen} key="search" alt="search icon"/>
                                <img src="/assets/images/profile.svg" className={styles.profileIcon} onClick={toggleIsProfileOpen} alt="profile icon"/>
                                <img src="/assets/images/hamburger.svg" className={styles.hamburger} onClick={toggleIsHambergerOpen} key="hamberger" alt="hamberger menu"/>
                            </div>
                        </div>
                        <div className={styles.searchOverlayContent}>
                            <div className={styles.searchBar}><Search /></div>
                        </div>
                    </div>
                    :
                    null
                }

                {/* // ------------------------------------- PROFILE */ }

                {isProfileOpen ?
                    <div className={styles.overlay}>
                        <div className={styles.content} onClick={toggleIsProfileOpen}>
                            <Link to="/" className={styles.logoContainer}> <img src="/assets/images/logo.png" className={styles.logo} ></img></Link>
                            <div className={styles.mobileTopRightIcons}>
                                <img src="/assets/images/searchIcon.svg" className={styles.searchIcon} onClick={toggleIsSearchOpen} key="search" alt="search icon"/>
                                <img src="/assets/images/profileDark.svg" className={styles.profileIcon} onClick={toggleIsProfileOpen} alt="profile icon"/>
                                <img src="/assets/images/hamburger.svg" className={styles.hamburger} onClick={toggleIsHambergerOpen} key="hamberger" alt="hamberger menu"/>
                            </div>
                        </div>
                        <div className={styles.overlayContent}>
                            <div className={styles.mobileProfileLinks}>
                                <Link to={`/account/${decodedUserID}`} className={styles.profileLink} key={"profile"}>Account</Link>
                                <div key={"signOut"} className={styles.signOut} onClick={signOut}>Sign Out</div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            </nav>
        )
    }

}