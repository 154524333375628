import React, { useState, useEffect } from "react";

import { useMedia, useMediaUpdate } from "../../contexts/MediaContext";

import styles from "./StatusEditor.module.css";

import { postReading, removeUsermediaFromLibrary, patchUsermedia } from "../../api/Api";

import { StatusDropdown } from "../StatusDropdown/StatusDropdown";
import { StatusDropdownItem } from '../StatusDropdown/StatusDropdownItem';


export const BookStatusEditor = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const mediaItem = useMedia();
    const setBook = useMediaUpdate();

    let token = localStorage.getItem("token");

    const [selectedLibrary, setSelectedLibrary] = useState();
    const [displayOptions, setDisplayOptions] = useState();

    const [stageToDelete, setStageToDelete] = useState();

    let allOptions = [ // all avaliable statuses
        <StatusDropdownItem key="library" displayValue="In Library" onClick={() => handleStatusChange("library")}></StatusDropdownItem>,
        <StatusDropdownItem key="TBR" displayValue="To Be Read" onClick={() => handleStatusChange("TBR")}></StatusDropdownItem>,
        <StatusDropdownItem key="currentlyReading" displayValue="Currently Reading" onClick={() => handleStatusChange("currentlyReading")}></StatusDropdownItem>,
        <StatusDropdownItem key="read" displayValue="Read" onClick={() => handleStatusChange("read")}></StatusDropdownItem>,
        <StatusDropdownItem key="DNF" displayValue="Did Not Finish" onClick={() => handleStatusChange("DNF")}></StatusDropdownItem>,
        <StatusDropdownItem key="delete" displayValue="Delete From Library" onClick={handleDelete}></StatusDropdownItem>,
    ];

    // only lets user select watch if book not out yet
    if (new Date(mediaItem.pub) > new Date) {
        allOptions.splice(1, 0 , <StatusDropdownItem key="watch" displayValue="Watch List" onClick={() => handleStatusChange("watch")}></StatusDropdownItem>)
    }

    const statusDict = { // mappings of backend status values to display values
        "read": "Read",
        "DNF": "Did Not Finish",
        "TBR": "To Be Read",
        "currentlyReading": "Currently Reading",
        "watch": "Watch List",
        "library": "In Library"
    }

    useEffect(() => { // sets dropdown options once props arrves and this selected library is set
        if (mediaItem) { 
            setDisplayOptions(allOptions.filter(isInList)) 
        }
    }, [selectedLibrary])

    useEffect(() => { //sets selected library once props arrives
        if (mediaItem && mediaItem.status) { 
            setSelectedLibrary(mediaItem.status) 
        } else {
            setSelectedLibrary(null);
        }
    }, [mediaItem.status])

    // ----------------------------------------------------- PRE-RENDER

    function isInList(i) {
        // checks if Dropdown item is currently selected and excludes it from options
        return (i.key !== selectedLibrary);
    }

    function handleStatusChange(newStatus) {
        // sets new status and initiates sending to backend
        setSelectedLibrary(newStatus);

        if (newStatus === "currentlyReading") {
            // to currentlyReading
            startReading();
        } else {
            // not starting a new reading
            updateStatus(newStatus);
        }
    }

    function handleDelete() {
        // initiates removal of book from a user's library
        setStageToDelete(true);
    }

    function handleCancelDelete() {
        // cancels removal
        setStageToDelete(false);
    }

    function handleConfirmDelete() {
        // removes userbook
        handleRemoveFromLibrary();
    }

    async function startReading() {
        // makes a new reading and attaches it the book

        const readingPostModel = {
            usermediaID :  mediaItem.usermediaID,
            start : new Date
        }

        await postReading(readingPostModel, token)
            .then((book) => {
                setBook(book);
            })
            .catch((error) => {
                console.log("failed to post reading");
                console.log(error)
            })
    }

    async function updateStatus(newStatus) {
        // updates book status without doing anything to a reading

        await patchUsermedia(mediaItem.usermediaID, { status: newStatus }, token)
            .then((book) => {
                setBook(book); //sets book as updated book
            })
            .catch((error) => {
                console.log("failed to change status");
                console.log(error)
            })
    }

    async function handleRemoveFromLibrary() {
        // deletes userbook, thus removing from library

        await removeUsermediaFromLibrary(mediaItem.usermediaID, token) 
            .then((book) => {
                setBook(book);
            })
            .catch((error) => {
                console.log("failed to remove book from library")
                console.log(error)
            })

    }
    
    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>

            <div className={styles.dropdown}>
                {stageToDelete ?
                    <div>
                        <h2 className={styles.message}> Are you sure? </h2>
                        <h5 className={styles.message}> Removing this book will PREMENETLY delete your rating, review, readings, and entries! </h5>
                        <button className={styles.deleteButton} onClick={handleConfirmDelete}>Confirm DELETE</button>
                        <button className={styles.cancelButton} onClick={handleCancelDelete}>Cancel</button>
                    </div>
                    :
                    <StatusDropdown buttonText={statusDict[selectedLibrary]} content={displayOptions} />}
            </div>


        </div>

    );
}
