import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styles from "./TagObject.module.css";
import { Tag } from "../../Tag/Tag";
import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";

export const TagBook = (props) => {
    // book object that shows cover, title, series, author, and tags

    // props:
    //
    // mediaItem - book object
    
    // ----------------------------------------------------- STATE AND HOOK

    const [isOpen, setIsOpen] = useState(false); //state of truncated review text block, open/close
    const [showReadMoreButton, setShowReadMoreButton] = useState(false);

    const [visiableTags, setVisableTags] = useState([]);
    const [allBookTags, setAllBookTags] = useState([]);

    const bookPath = `/${props.mediaItem.type}/` + props.mediaItem.mediaID;
    const seriesPath = "/series/" + props.mediaItem.series?.id;

    useEffect(()=> {
        // handles tags on first render
        if (props.mediaItem && props.mediaItem.tags) {
            // makes tag objects
            let temp = []
            for (let i = 0; i < props.mediaItem.tags.length; i++) {
                temp.push(<Tag tagItem={props.mediaItem.tags[i]} key={i} />)
            }
            setAllBookTags(temp);

            // determins how many to show initally
            if (window.screen.width > 500 && temp.length > 2) {
                // DESKTOP
                // show first 2
                setShowReadMoreButton(true);
                setVisableTags(temp.slice(0,2));
            } else if (window.screen.width < 500 && temp.length > 1) {
                // MOBILE
                // show first 1
                setShowReadMoreButton(true);
                setVisableTags(temp.slice(0,1));
            } else {
                // shows all tags
                setVisableTags(temp)
                setShowReadMoreButton(false);
            }
        }
    },[props.mediaItem.tags])
    

    // ----------------------------------------------------- PRE-RENDER

    function toggleTags () {
        // toggles the visiable tags
        setIsOpen(!isOpen);
        
        if (!isOpen === true) {
            // show all
            setVisableTags(allBookTags);
        } else if (!isOpen === false && window.screen.width > 500) {
            // show first 2 for desktop
            setVisableTags(allBookTags.slice(0,2));
        } else {
            // show first 1 for mobile
            setVisableTags(allBookTags.slice(0,1));
        } 
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}> 
                <Cover mediaItem = {props.mediaItem} size = {window.screen.width > 500 ? "L" : "M"} loc="tagBook"/>
                <div className={styles.bookInfo}>   
                    <Link to={bookPath} className={styles.link}><h2 className={styles.bookTitle}>{props.mediaItem.title}</h2></Link>
                    {props.mediaItem.series != null ?
                    <div>
                        <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{props.mediaItem.series?.name} #{props.mediaItem.seriesNum}</h3></Link>
                    </div> :
                    null}
                    <Author authorsList={props.mediaItem.creators} size={window.screen.width > 500 ? 1 : 0} link={true} />
                    <div className={styles.tags}>
                        <div className={isOpen ? styles.tagsInBook : styles.tagsInBookClosed}>{visiableTags}</div>
                        {showReadMoreButton ? (
                            <button className={styles.readMoreOrLessButton}
                                onClick={toggleTags}>
                                {isOpen ?
                                    <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                                    :
                                    <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                                }
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
            
        </section>
    );
}