import React, { useEffect, useState } from "react";
import styles from "./StatusEditor.module.css";
import { useMedia, useMediaUpdate } from "../../contexts/MediaContext";

import { StatusDropdown } from "../StatusDropdown/StatusDropdown";
import { StatusDropdownItem } from '../StatusDropdown/StatusDropdownItem';
import { patchUsermedia } from "../../api/Api";

export const TVShowStatusEditor = (props) => {

    // ----------------------------------------------------- STATE AND HOOKS
    const mediaItem = useMedia();
    const updateMediaItem = useMediaUpdate();

    let token = localStorage.getItem("token");

    const [stageToDelete, setStageToDelete] = useState();
    const [selectedLibrary, setSelectedLibrary] = useState(mediaItem.status);
    const [displayOptions, setDisplayOptions] = useState();

    useEffect(() => {
        // updates dropdown when value changes
        setDisplayOptions(allOptions.filter(isInList));
    },[mediaItem.status, selectedLibrary]);
    // ----------------------------------------------------- PRE-RENDER

    let allOptions = [ // all avaliable statuses
        <StatusDropdownItem key="library" displayValue="In Library" onClick={() => handleStatusChange("library")}></StatusDropdownItem>,
        <StatusDropdownItem key="TBR" displayValue="To Be Watched" onClick={() => handleStatusChange("TBR")}></StatusDropdownItem>,
        <StatusDropdownItem key="currentlyReading" displayValue="Currently Watching" onClick={() => handleStatusChange("currentlyReading")}></StatusDropdownItem>,
        <StatusDropdownItem key="read" displayValue="Seen" onClick={() => handleStatusChange("read")}></StatusDropdownItem>,
        <StatusDropdownItem key="DNF" displayValue="Did Not Finish" onClick={() => handleStatusChange("DNF")}></StatusDropdownItem>,
        <StatusDropdownItem key="delete" displayValue="Delete From Library" onClick={handleDelete}></StatusDropdownItem>,
    ];

    const statusDict = { //mappings of backend status values to display values
        "read": "Seen",
        "DNF": "Did Not Finish",
        "TBR": "To Be Watched",
        "currentlyReading": "Currently Watching",
        "watch": "Not Out Yet",
        "library": "In Library"
    }

    function isInList(i) {
        // checks if Dropdown item is currently selected and excludes it from options
        return (i.key !== selectedLibrary);
    }

    async function handleStatusChange(newStatus) {
        // sends new status to backend and updates tvshowItem

        setSelectedLibrary(newStatus); // triggers rerender of dropdown

        await patchUsermedia(mediaItem.usermediaID, {status : newStatus}, token)
            .then((updatedTVShow) => {
                updateMediaItem(updatedTVShow);
            })
            .catch((error) => {
                console.log(error, "failed to update tvshow status");
            });
    }

    function handleDelete() {
        // initiates removal of book from a user's library
        setStageToDelete(true);
    }

    function handleCancelDelete() {
        // cancels removal
        setStageToDelete(false);
    }

    function handleConfirmDelete() {
        // removes userbook
        // handleRemoveFromLibrary();
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            { mediaItem.usermediaID ? 
                <div className={styles.dropdown}>
                    {stageToDelete ? 
                        <div>
                            <h2 className={styles.message}> Are you sure? </h2>
                            <h5 className={styles.message}> Removing this book will PREMENETLY delete your rating, review, readings, and entries! </h5>
                            <button className={styles.deleteButton} onClick={handleConfirmDelete}>Confirm DELETE</button>
                            <button className={styles.cancelButton} onClick={handleCancelDelete}>Cancel</button>
                        </div>
                        : 
                        <StatusDropdown buttonText={statusDict[selectedLibrary]} content={displayOptions} />}
                </div> 
                :
                <button></button>
            }

        </section>
    )
}