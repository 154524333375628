import React, { useEffect, useState } from "react";
import styles from "./BookSuggestion.module.css";

import { Author } from "../../Author/Author";

export const BookSuggestion = (props) => {

    // props:
    // 
    // mediaItem - object containing book information
    // setSelectedBook - state var setter from parent to propogate selection

    // ----------------------------------------------------- STATE AND HOOKS
    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container} onClick={ () => props.setSelectedBook(props.mediaItem) }>
            <h4 className={styles.title}>{props.mediaItem.title}</h4>
            <Author authorsList={props.mediaItem.creators} size={0} link={false} />
        </section>
    )
}