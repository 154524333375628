import React, { useState } from "react";
import styles from "./Episode.module.css";
import { useMedia } from "../../contexts/MediaContext";

export const Episode = (props) => {

    // props:
    // 
    // episodeItem - object containing episode information
    // noBlurSeason - bool, defaults blur to false when true

    // ----------------------------------------------------- STATE AND HOOKS
    let mediaItem = useMedia();

    const [blur, setBlur] = useState(mediaItem.progress < props.episodeItem.overallepisode && !props.noBlurSeason)
    // ----------------------------------------------------- PRE-RENDER

    function makeDate(date) {
        // formats date in consistent

        const temp = new Date(date)
        const converted = new Date(temp.getTime() - temp.getTimezoneOffset() * -60000).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric' });

        return converted;
    }

    const toggleBlurr = () => {
        // toggles blurr effect
        setBlur(!blur);
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container} key={props.episodeItem.episodeID}>
            <div className={styles.episodeNumberGroup}>
                <h1 className={styles.title}>{props.episodeItem.episodeofseason}. {props.episodeItem.title}</h1>
                <h3 className={styles.overallNum}>{props.episodeItem.overallepisode}</h3>
            </div>
            <h3 className={styles.airdate}>{makeDate(props.episodeItem.airdate)}</h3>
            {blur ?
                <div className={styles.blurredContainer}>
                    <div className={styles.descriptionBlurred}>
                        {props.episodeItem.description}
                    </div>
                    <div className={styles.filler}/>
                    <button className={styles.iconContainerBlur}><img src="/assets/images/eye-open.svg" alt="unblur icon" className={styles.iconBlur} onClick={toggleBlurr}/></button>
                </div>
                :
                <div className={styles.unblurredContainer}>
                    <div className={styles.description}>
                        {props.episodeItem.description}
                    </div>
                    <button className={styles.iconContainerUnblur}><img src="/assets/images/eye-closed.svg" alt="blur icon" className={styles.iconUnblur} onClick={toggleBlurr}/></button>
                    <div className={styles.fillerLarge}/>
                </div>

            }


        </section>
    )
}