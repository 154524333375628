import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import styles from "./CatalogBook.module.css";
import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";
import Clamp from "react-multiline-clamp";

export const CatalogBook = (props) => {

    // props:
    // 
    // mediaItem - object containing book information

    // ----------------------------------------------------- STATE AND HOOKS

    const [formattedDescripton, setFormattedDescription] = useState();

    useEffect(() => {
        // formats description on first render
        setFormattedDescription(formatDescription())
    },[])


    // ----------------------------------------------------- PRE-RENDER

    let bookPath = `/${props.mediaItem.type}/`  + props.mediaItem.mediaID;
    let seriesPath = "/series/" + props.mediaItem.series?.id;

    function formatDescription() {
        //adding white space to the description

        // no description
        if (props.mediaItem.description === null) return <div key="no description">No available description</div>;

        // has description
        let inProgressDescription = []
        //splits description string by newlines
        let descriptionPeices = props.mediaItem.description.split('\n'); 
        if (descriptionPeices.length === 1) { 
            //no new lines
            return props.mediaItem.description;
        } else {
            // iterates through new lines
            for (let i = 0; i < descriptionPeices.length; i++) {
                if (!descriptionPeices[i]) { 
                    //if empty it means there was a blank line
                    inProgressDescription.push(<div key={i} className={styles.spacer}></div>)
                } else {
                    //adds string segment to array
                    inProgressDescription.push(<div key={i}>{descriptionPeices[i]}</div>) 
                }
            }
            return inProgressDescription;
        }
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <Cover mediaItem={props.mediaItem} loc = "catalog" size="L" />
            <div className={styles.bookInfo}>
                <Link to={bookPath} className={styles.link}><h1 className={styles.bookTitle}>{props.mediaItem.title}</h1></Link> 
                {props.mediaItem.series != null ?
                                <div>
                                    <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{props.mediaItem.series?.name} #{props.mediaItem.seriesNum}</h3></Link>
                                </div> :
                                null}
                <div className={styles.author}><Author authorsList={props.mediaItem.creators} size={window.screen.width > 500 ? 1 : 0} link={true} /></div>
                <Clamp lines={4}
                        maxLines={100}
                        withTooltip={false}
                        withToggle={true}
                        showMoreElement={({ toggle }) => (
                            <button className={styles.readMoreOrLessButton}
                                onClick={toggle} >
                                <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                            </button>
                        )}
                        showLessElement={({ toggle }) => (
                            <button className={styles.readMoreOrLessButton}
                                onClick={toggle} >
                                <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                            </button>
                        )}>
                        <div className={styles.bookDescription}>{formattedDescripton} </div>
                    </Clamp>
            </div>
        </section>
    )
}