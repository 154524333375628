import React, { useState, useEffect } from "react";
import styles from "./TVShowBanner.module.css";

import { useMedia } from "../../contexts/MediaContext";
import { BookInfoBox } from "../ObjectInfoBoxs/MediaInfoBox";
import { TVShowEditionBox } from "../EditionBox/TVShowEditionBox";
import { EpisodeList } from "../EpisodeList/EpisodeList";
import { ReviewBox } from "../ReviewBox/ReviewBox";
import { FieldsBox } from "../FieldsBox/FieldsBox";

export const TVShowBanner = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    const mediaItem = useMedia();


    useEffect(() => { // scrolls to top on first render
        window.scroll(0, 0);
    }, [])

    // ----------------------------------------------------- PRE-RENDER

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.rowOne}>
                <BookInfoBox />
                <TVShowEditionBox />
            </div>
            {mediaItem.usermediaID ? // in library ?
                <div className={styles.rowTwo}>
                    <FieldsBox />
                    <ReviewBox />
                </div>
                :
                null
            }
            <div className={styles.rowThree}>
                <EpisodeList />
            </div>
        </section>
    )
}