import React, { useEffect, useState } from "react";
import styles from "./EpisodeList.module.css";
import { useMedia } from "../../contexts/MediaContext";
import { SeasonHeader } from "./SeasonHeader";
import { Episode } from "./Episode";

export const EpisodeList = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const mediaItem = useMedia();

    const [seasons, setSeasons] = useState(mediaItem.seasons);
    const [selectedSeasons, setSelectedSeason] = useState(Array(mediaItem.seasons).fill(false));

    const [noBlurSeaon, setNoBlurSeason] = useState(false);

    let sortedEpisodes = sortEpisodes();

    useEffect(() => {
        // makes season headers when tvshowItem arrives and when selected season changes
        if (mediaItem.seasons && mediaItem.seasons !== 0) {
            makeSeasonObjects();
        }
    },[mediaItem, selectedSeasons])

    useEffect(() => {
        makeSeasonObjects();
    }, [noBlurSeaon])

    // ----------------------------------------------------- PRE-RENDER

    function sortEpisodes() {
        // sorts episodes into seasons list
        var lastSeen = 1;
        let sorted = []
        var currentSeason = [];

        for (let i = 0; i < mediaItem.episodes.length; i++){
            if (lastSeen !== mediaItem.episodes[i].season) {
                // into next season, push current list

                sorted.push(currentSeason);
                currentSeason = [];
                lastSeen = lastSeen + 1;

                currentSeason.push(mediaItem.episodes[i]);
            } else {
                
                // in same season as i-1
               currentSeason.push(mediaItem.episodes[i]);
            }
        }
        sorted.push(currentSeason);
        return sorted;
    }

    function makeSeasonObjects() {
        // makes season header objects
        let temp = [];

        for (let i = 0; i < mediaItem.seasons; i++) {
            // adds season header

            temp.push(
                <div>
                    <div key={i + 1} onClick={() => handleSeasonClick(i)}>
                        <SeasonHeader seasonNum={i + 1} isSelected={selectedSeasons === i + 1} toggleBlur={toggleSeasonBlur} initalBlur={mediaItem.progress < sortedEpisodes[i].at(-1).overallepisode} />
                    </div>
                    <div className={styles.blurToggle}>

                    </div>
                </div>

            )
            if (selectedSeasons[i] === true) {
                // adds episodes to list if seasons is selected
                let episodeTemp = []

                for (let j = 0; j < sortedEpisodes[i].length; j++) {
                    episodeTemp.push(<div key={"ep"+j}><Episode episodeItem={sortedEpisodes[i][j]}/></div>)
                }
                temp.push(<div className={styles.episodesInSeason} key={"epsInSes"+i}>{episodeTemp}</div>); 
            }
        }
        setSeasons(temp);
    }

    function handleSeasonClick(newSeason) {
        // handles a click to season header object
        let temp = [];

        for (let i = 0; i < mediaItem.seasons; i++) {
            // toggles between true and false
            if (i === newSeason && selectedSeasons[i] !== true) temp.push(true);
            if (i !== newSeason) temp.push(selectedSeasons[i]);
            else temp.push(false);
        }
        setSelectedSeason(temp);
    }

    const toggleSeasonBlur = () => {
        // toggles no blur season status
        setNoBlurSeason(!noBlurSeaon);
    }



    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <section className={styles.seasons}>
                {seasons}
            </section>
        </section>
    )
}