import React, { useState, useEffect } from "react";

import { getMediaByLibrary } from '../../api/Api';
import { WatchBook } from '../BookTypes/WatchBook/WatchBook';

import styles from "./WatchList.module.css";

export const WatchList = (props) => {

    // props:
    //
    // page - currently selected page

    // ----------------------------------------------------- STATE AND HOOKS

    const [books, setBooks] = useState([]);
    const [listOfMedia, setListOfMedia] = useState([]);

    let token = localStorage.getItem("token");

    useEffect(() => {
        fetchBooks();
    }, []);

    // ----------------------------------------------------- PRE-RENDER

    async function fetchBooks() {
        // gets book page from back

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        var pageSize = 3;
        if (window.screen.width < 500) pageSize = 2;

        await getMediaByLibrary("watch", token)
        .then((foundMedia) => {
            if (foundMedia.length === 0) {
                setListOfMedia(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
            } else {
                setBooks(foundMedia);
                makeMediaItems(foundMedia);
            }

        })
        .catch((error) => {
            console.log("failed to get TBR media", error)
        })
    }

    function makeMediaItems(foundBooks) {
        //makes list of books
        if (foundBooks.length > 0) {
            let tempList = []
            for (let i = 0; i < foundBooks.length; i++) {
                tempList.push(
                    <div key={foundBooks[i].isbn}><WatchBook mediaItem={foundBooks[i]} /></div>
                )
            }
            setListOfMedia(tempList);
        } else {
            setListOfMedia(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
        }
    }

    // ----------------------------------------------------- RENDER


    return(
        <section className={styles.container}>
            <div className={ styles.content}>
               {listOfMedia}
            </div>
        </section>
    );
}