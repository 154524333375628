import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import styles from "./AuthorWorkBook.module.css";
import { Cover } from "../../Cover/Cover";

export const AuthorWorkBook = (props) => {
    
    // props:
    //
    // mediaItem - object containing book information
    // ----------------------------------------------------- STATE AND HOOKS

    const [isOpen, setIsOpen] = useState(false); //state of truncated review text block, open/close
    const [showReadMoreButton, setShowReadMoreButton] = useState(false);

    const [firstWordsOfTitle, setFirstWordsOfTitle] = useState("");
    const [lastWordOfTitle, setLastWordOfTitle] = useState("");

    let formattedFullDescripton = formatDescription();

    const seriesPath = "/series/" + props.mediaItem.series?.id;

    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            setShowReadMoreButton(ref.current.scrollHeight !== ref.current.clientHeight)
        }
    }, [ref])

    useEffect(()=>{
        if (props.mediaItem && props.mediaItem.title) { processTitle() }
    },[props.mediaItem])

    // ----------------------------------------------------- PRE-RENDER

    const bookPath = `/${props.mediaItem.type}/` + props.mediaItem.mediaID;

    function formatDescription() {
        let formattedDescripton = []
        let descriptionPeices = props.mediaItem.description.split('\n'); //splits description string by newlines
        if (descriptionPeices.length === 1) { //no new lines
            return props.mediaItem.description;
        } else {
            for (let i = 0; i < descriptionPeices.length; i++) {
                if (!descriptionPeices[i]) { //if empty it means there was a blank line
                    formattedDescripton.push(<div key={i} className={styles.spacer}></div>)
                } else {
                    formattedDescripton.push(<div key={i}>{descriptionPeices[i]}</div>) //adds string segment to array
                }
            }
            return (formattedDescripton);
        }
    }

    function processTitle() {
        // splits title into two peices and adds star to second to prevent weird line wrapping
        let words = props.mediaItem.title.split(" ");
        let lastWord = words.pop();

        const firstWords = words.join(" ") + " "

        if (props.mediaItem.favorited) {
            lastWord = [lastWord , <img key="star" className={styles.starIconFilled} alt="Favorite star" />];
        }
        else {
            lastWord = [lastWord];
        }

        setFirstWordsOfTitle(firstWords);
        setLastWordOfTitle(lastWord);
    }

    // ----------------------------------------------------- RENDER
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Cover mediaItem = {props.mediaItem} size={window.screen.width > 500 ? "L" : "M"} loc="authorBook" />
                <div className={styles.bookInfo}>
                    <Link to={bookPath} className={styles.link}>
                        <div>
                            <span className={styles.firstWordsOfTitle}>{firstWordsOfTitle}</span>
                            <span className={styles.lastWordOfTitle}>{lastWordOfTitle}</span>
                        </div>
                    </Link>
                    {props.mediaItem.series != null ?
                    <div>
                        <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{props.mediaItem.series?.name} #{props.mediaItem.seriesNum}</h3></Link>
                    </div> :
                    null}
                    <div>
                    <div className={isOpen ? styles.bookDescriptionOpen : styles.bookDescriptionClosed} ref={ref}>{formattedFullDescripton}</div>
                    {showReadMoreButton ? (
                        <button className={styles.readMoreOrLessButton}
                            onClick={() => setIsOpen(!isOpen)}>
                            {isOpen ? 
                                <img className={styles.carrot} src= "/assets/images/upCarrot.svg" alt="read less"/> 
                                : 
                                <img className={styles.carrot} src= "/assets/images/downCarrot.svg" alt="read more"/>
                            }
                        </button>
                    ): null}
                </div>
                </div>
            </div>
        </div>
    )
}