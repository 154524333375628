// helper function to scroll page to top

function ScrollToTop() {

    const makeDelay = async () => {
        await delay(50);
        window.scrollTo(0,0)
    };
    
    const delay = async (ms) => {
        return new Promise((resolve) => 
            setTimeout(resolve, ms));
    };

    makeDelay();
}

export default ScrollToTop;