import React from "react";
import styles from "./EntryReadingHeader.module.css";
import { Reading } from "../Reading/Reading";

export const EntryReadingHeader = (props) => {
    // props: 
    // 
    // mediaItem - object containing book infromation
    // readingItem - object containing this readings information

    // ----------------------------------------------------- STATE AND HOOK

    // ----------------------------------------------------- PRE-RENDER

    function nthReading(reading) {

        var n = 0
        for (let i = 0; i < props.mediaItem.readings.length; i++) {
            console.log(props.mediaItem.readings[i])
            if ( props.mediaItem.readings[i].readingID == reading.readingID) {
                n = i + 1 // found index
            }
        }

        var special = ['Zeroth', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth', 'Eleventh', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth', 'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth'];
        var deca = ['Twent', 'Thirt', 'Fort', 'Fift', 'Sixt', 'Sevent', 'Eight', 'Ninet'];

        if (n < 20) {
            // less than 20
            return special[n];
        } else if (n % 10 === 0) {
            // even multiple of 10
            return deca[Math.floor(n / 10) - 2] + 'ieth';
        } else {
            // non-even multiple of 10
            return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
        }
    
    }

    function makeHeader() {

        if (props.readingItem === null) {
            // no reading
            return (
                <div>
                    <h3 className={styles.headerText}>No Associated Reading</h3>
                </div>
            )
        } else if (props.readingItem.readingID == props.mediaItem.currentReading?.readingID) {
            // current reading
            return (
                <div>
                    <h3 className={styles.headerText}>Current Reading</h3>
                    <div className={styles.readingDates}><Reading readingItem={props.readingItem} /></div>
                </div>
            )
        } else {
            // past reading
            return (
                <div>
                    <h3 className={styles.headerText}>{nthReading(props.readingItem)} Reading</h3>
                    { props.readingItem.status == "DNF" ? 
                        <h4 className={styles.dnfText}>Did Not Finish</h4> 
                        : 
                        null
                    }
                    <div className={styles.readingDates}><Reading readingItem={props.readingItem} /></div>
                </div>
            )
        }
    }

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            {makeHeader()}
        </section> 
    )
}