import React from "react";

import styles from "./FieldsBox.module.css";

import { StarRating } from "../StarRating/StarRating";
import { TagEditor } from "../TagEditor/TagEditor";
import { BookStatusEditor } from "../StatusEditor/BookStatusEditor";
import { Readings } from "../Readings/Readings";
import { useMedia, useMediaUpdate } from "../../contexts/MediaContext";
import { BookProgressUpdater } from "../ProgressUpdater/BookProgressUpdater";
import { TVShowProgressUpdater } from "../ProgressUpdater/TVShowProgressUpdater";
import { TVShowStatusEditor } from "../StatusEditor/TVShowStatusEditor";


export const FieldsBox = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const mediaItem = useMedia();
    const mediaUpdater = useMediaUpdate();

    let location = window.location.href

    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <h3 className={styles.infoTitle}>Status</h3>
            {location.includes("/book") ?
                <BookStatusEditor />
                :
                <TVShowStatusEditor />
            }
            {location.includes("/book") && mediaItem.status === "currentlyReading" ?
                <div>
                    <h3 className={styles.infoTitle}>Progress</h3>
                    <BookProgressUpdater mediaItem={mediaItem} bookUpdater={mediaUpdater} />
                </div>
                :
                null
            }
            {location.includes("/tvshow") && mediaItem.status === "currentlyReading" ?
                <div>
                    <h3 className={styles.infoTitle}>Progress</h3>
                    {mediaItem.progress >= mediaItem.numPages ?
                        null
                        :
                        <h4 className={styles.infoSubTitle}>Next Episode: {mediaItem.episodes[mediaItem.progress].title}</h4>
                    }
                    <TVShowProgressUpdater tvshowItem={mediaItem} tvshowUpdater={mediaUpdater} />
                </div>
                :
                null

            }

            <h3 className={styles.infoTitle}>Rating</h3>
            <StarRating />
            <Readings />
            <TagEditor />
        </div>
    );
}
