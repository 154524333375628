import React, { useState, useEffect } from "react";
import styles from "./RecentlyAdded.module.css";

import { getMediaPage } from '../../api/Api';
import { TBRbook } from "../BookTypes/TBRBook/TBRBook";
import { Loader } from "../Loader/Loader";

export const RecentlyAdded = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const [books, setMedia] = useState([]);
    const [mediaObjects, setMediaObjects] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [cssModifier, setCSSModifier] = useState(null);

    let token = localStorage.getItem("token");

    useEffect(() => {
        fetchBooks();
    }, []);

    // ----------------------------------------------------- PRE-RENDER

    async function fetchBooks() {
        // gets book page from back

        var pageSize = window.innerWidth > 500 ? Math.floor(window.innerWidth / 198) : Math.floor(window.innerWidth / 150); // number of books across based on window size;

        let tempCSS = "1fr ";
        setCSSModifier(tempCSS.repeat(pageSize));

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        await getMediaPage(1, pageSize, "recent", "usermediaID", false, token)
            .then((foundMedia) => {
                if (foundMedia.length === 0) {
                    setMediaObjects(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
                } else {
                    setMedia(foundMedia);
                    makemediaItems(foundMedia)
                }
            })
            .catch((error) => {
                console.log("failed to get recently added media", error)
                setIsLoading(false)
            })
    }

    function makemediaItems(foundBooks) {
        // makes book items
        if (foundBooks.length > 0) {
            let tempList = []
            for (let i = 0; i < foundBooks.length; i++) {
                tempList.push(
                    <div key={foundBooks[i].mediaID + "RECENT"}><TBRbook mediaItem={foundBooks[i]} /></div>
                )
            }
            setMediaObjects(tempList);
        }
        else {
            setMediaObjects(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
        }

        setIsLoading(false)
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            {isLoading ?
                <div className={styles.loader}>
                    <Loader />
                </div>
                :
                <div className={styles.content} style={{ gridTemplateColumns: cssModifier }}>
                    {mediaObjects}
                </div>
            }
        </section>
    )
}