import React, { useEffect, useState } from 'react';
import styles from "./CurrentlyReading.module.css";
import { getMediaByLibrary, getMediaPage } from '../../api/Api';


import { CurrentlyReadingBook } from "../BookTypes/CurrentlyReadingBook/CurrentlyReadingBook";

export const CurrentlyReading = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    const [media, setMedia] = useState([]);
    const [mediaObjects, setMediaObjects] = useState()

    let token = localStorage.getItem("token");

    useEffect(() => { //runs on first render
        fetchMedia();
    }, []);

    // ----------------------------------------------------- LOGIC

    function reloadBooks() {
        fetchMedia();
    }

    function makeMediaItems(foundMedia) {
        //makes media render objects from fetched medias
        try {
            // trys to sorts media by marked as reading order
            let sortedMedia = foundMedia.sort(function (a,b) {
                if (a?.currentReading.readingID > b?.currentReading.readingID) {
                    return -1;
                }
                else return 1;
            })
            
            // makes objecs
            if (sortedMedia.length > 0)  {
                const listOfCurrentlyReading = sortedMedia.map((media) =>
                    <CurrentlyReadingBook mediaItem={media} reload={reloadBooks} key={media.type + media.mediaID} />
                );
                setMediaObjects(listOfCurrentlyReading);
    
            } else {
                setMediaObjects (
                    <div className={styles.emptyShelf}>
                        <h1>This shelf is currently empty</h1>
                    </div>
                );
            }
        } catch {
            // If can't sort, makes them in whatever order they arrived
            if (foundMedia.length > 0)  {
                const listOfCurrentlyReading = foundMedia.map((media) =>
                    <CurrentlyReadingBook mediaItem={media} reload={reloadBooks} key={media.type + media.mediaID} />
                );
                setMediaObjects(listOfCurrentlyReading);
    
            } else {
                setMediaObjects (
                    <div className={styles.emptyShelf}>
                        <h1>This shelf is currently empty</h1>
                    </div>
                );
            }
        }

    }

    async function fetchMedia() {
        // gets currently reading books
        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        await getMediaByLibrary("currentlyReading", token)
            .then((foundMedia) => {
                setMedia(foundMedia);
                makeMediaItems(foundMedia)
            })
            .catch((error) => {
                console.log("failed to fetch media", error);
            })
    }

    // ----------------------------------------------------- RENDER



    return (
        <section className={styles.container}>
            <h1 className={styles.sectionTitle}>Current</h1>
            <div className={styles.content}>
                {mediaObjects}
            </div>
        </section>

    );

}