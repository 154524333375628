import React from "react";
import styles from "./SeasonHeader.module.css";

export const SeasonHeader = (props) => {

    // props:
    // 
    // seasonNum - number season
    // isSelected - bool, if season has been selected


    // ----------------------------------------------------- STATE AND HOOKS
    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <h1 className={styles.header}>Season {props.seasonNum}</h1>
        </section>
    )
}