import React, { useEffect } from "react";
import styles from "./pages.module.css";
import { CatalogBanner } from "../comonents/Banners/CatalogBanner";
import { Footer } from "../comonents/Footer/Footer";
import { useMediaUpdate } from "../contexts/MediaContext";

export default function Catalog () {

    // ----------------------------------------------------- STATE AND HOOKS

    const bookUpdate = useMediaUpdate();

    useEffect(() =>{
        // clears stored book to avoid flash of previous book 
        // (particularlly jaring when book might not both be in library/not in library)

        bookUpdate({});
    },[])

    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <CatalogBanner />
            </div>
            <Footer />
        </section>
    )
}