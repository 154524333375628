import React from "react";
import { Link } from "react-router-dom";
import styles from "./Tag.module.css";

export const Tag = (props) => {

    // props:
    //
    // tagItem = Tag item incluing (int) id and (string) tagValue
    // deleteButtonClick = function from TagEditor that removes a tag from a book (optional, defualt null will cause no behavoir)
    // isEditing = state var from TagEditor that says if user is in edit tag mode (optional, default null will never show button)
    // selected = int id of tag currently selected by TagBanner (optional, default null is same as false)

    // ----------------------------------------------------- PRE-RENDER

    let tagPath = "/tags/" + props.tagItem.tagID;

    let isSelected = false;
    if (props.tagItem.tagID == props.selected) { //determins if this tag is the currently selected one on tag page
        isSelected = true;
    };

    // ----------------------------------------------------- RENDER

    return (
        <div>
            {props.isEditingTag ? // is editing = true then show version where whole thing is NOT clickable
                <div className={isSelected ? styles.containerSelected : styles.container}>
                    <div className={styles.gridContainer}>
                    <Link to={tagPath} className={styles.link}>
                        <button className={styles.tagValue}>{props.tagItem.tagValue}</button>
                    </Link>
                    <button className={styles.deleteButton} onClick={() => { props.deleteButtonClick(props.tagItem) }}>
                        <img className={styles.deleteButtonIcon} alt="delete icon"></img>
                    </button>
                    </div>
                </div> 
                :  // otherwise show version where whole container IS clickable
                <Link to={tagPath} className={styles.link}>
                    <div className={isSelected ? styles.containerSelected : styles.container}>
                        <button className={isSelected ? styles.tagValueSelected : styles.tagValue}>{props.tagItem.tagValue}</button>
                    </div>
                </Link>
            }
        </div>
    );
}