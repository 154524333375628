import React from "react";
import styles from "./EditionBox.module.css";
import { useMedia } from "../../contexts/MediaContext";

export const TVShowEditionBox = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const mediaItem = useMedia();

    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <h4 className={styles.header}>Seasons:</h4>
            <div className={styles.value}>{mediaItem.seasons}</div>

            <h4 className={styles.header}>Episodes:</h4>
            <div className={styles.value}>{mediaItem.numPages}</div>

            <h4 className={styles.header}>Run:</h4>
            <div className={styles.value}>{mediaItem.startyear} - {mediaItem.endyear !== null ? mediaItem.endyear : "present"}</div>

            <h4 className={styles.header}>Parent Guied:</h4>
            <div className={styles.value}>{mediaItem.parentguied}</div>


            {mediaItem.genres.length > 0 ?
                <div>
                    <h4 className={styles.header}>Genre:</h4>
                    <div className={styles.genreList}>{mediaItem.genres.map((g) => <div className={styles.genre} key={g.genreID}>{g.genreNAME}</div>)}</div>
                </div>
                :
                null
            }
        </section>
    )
}