import React, { useState, useContext, createContext } from "react";

export const MediaContext = createContext(null);
export const MediaUpdateContext = createContext(null);

export function useMedia() {
    //allows reading of book data
    const media = useContext(MediaContext);
    if (media === undefined) {
        console.log("There has been an error, check MediaContext.js");
    }
    return media;
}

export function useMediaUpdate() {
    //allows writing of book data
    const mediaUpdate = useContext(MediaUpdateContext);
    if (mediaUpdate == undefined){
        console.log("There has been an error, check MediaContext.js");
    }
    return mediaUpdate
}

export function MedaiContextProvider( {children} ) {

    const [mediaItem, setMediaItem] = useState({});

    const setMedia = (media) => {
        //sets book item
        setMediaItem(media)
    }
    
    return (
        <MediaContext.Provider value={mediaItem} >
            <MediaUpdateContext.Provider value={setMedia}>
                {children} 
            </MediaUpdateContext.Provider>
        </MediaContext.Provider>
    );
}