import React, { Children, useEffect, useLayoutEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import styles from './App.module.css';

import Library from './pages/Library';
import Home from './pages/Home';
import Book from './pages/Book';
import Tag from './pages/Tag';
import Author from './pages/Author';
import Series from './pages/Series';
import TVShow from './pages/TVShow';
import { AddBook } from './pages/AddBook';
import { NavigationBar } from "./comonents/NavBar/NavigationBar";

import { MedaiContextProvider } from './contexts/MediaContext';
import { Login } from './pages/Login';

import { Account } from './pages/Account';
import { SearchContextProvider } from './contexts/SearchContext';
import { verifyToken } from './api/Api';
import Catalog from './pages/Catalog';
import { BugReport } from './pages/BugReport';


function App() {

  // --------------------------------------- STATE AND HOOKS

  useEffect(() => {
    // manages cache on first render

    // removes old cache
    caches.keys().then((keyList) => {
      keyList.map((key) => {
        if (key !== `earmarked-cover-cache-${new Date().toISOString().slice(0, 10)}`) {
          console.log("removed old cache: ", key);
          return caches.delete(key);
        }
      })
    })

    // creates today's cache
    caches.open(`earmarked-cover-cache-${new Date().toISOString().slice(0, 10)}`)
      .then((cache) => {
        console.log("succesfully opened image cache")
      })
      .catch((error) => {
        console.log("failed to open image cache");
        console.log(error);
      })

  }, []);

  // --------------------------------------- PRE-RENDER

  const [token, setToken] = useState(getToken());

  function putToken(userToken) {
    // puts token in local storage
    setToken(userToken); //drive re-render
    localStorage.setItem('token', JSON.stringify(userToken));
    
  }

  function getToken() {

    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    // checks that backend hasn't restarted/token is still good
    if (tokenString !== null) {
        const goodToken = verifyFoundToken(userToken);
        if (goodToken) return userToken;
        else return false;
    }

  }

  async function verifyFoundToken(foundToken) {
    // checks that token is still good
    await verifyToken(foundToken)
      .then((response) =>{
        // removes token from storage if it is bad
        if (response === false) {
          localStorage.removeItem('token');
          return false;
        } 
      })
      .catch((error) => {
        console.log("failed to verify existing token")
        console.log(error)
      })
  }

  // --------------------------------------- RENDER

  if (!token) {
    return (<Login putToken={putToken} />)
  }

  return (
      <MedaiContextProvider>
        <SearchContextProvider>
          <NavigationBar />
          <div className={styles.App}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/library" element={<Library />} />
              <Route path="/catalog" element={<Catalog />} />
              <Route path="/book">
                <Route path=":bookID" element={<Book />} />
              </Route>
              <Route path="/tags">
                <Route path=":id?" element={<Tag />} />
              </Route>
              <Route path="/author">
                <Route path=":id" element={<Author />} />
              </Route>
              <Route path="/series">
                <Route path=":id" element={<Series />} />
              </Route>
              <Route path="/addbook" element={<AddBook />} />
              <Route path='/account'>
                <Route path=':id' element={<Account />} />
              </Route>
              <Route path='/tvshow'>
                <Route path=':tvshowID' element={<TVShow />} />
              </Route>
              <Route path='/bugreport' element={<BugReport />} />
            </Routes>
          </div>
        </SearchContextProvider>
      </MedaiContextProvider>
  );
}

export default App;
