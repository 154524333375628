import React from "react";
import styles from "./RecommendationBook.module.css";
import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";
import { Link } from "react-router-dom";

export const RecommendationBook = (props) => {

    // props:
    // 
    // recItem - object containing recommendation book information

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    
    let mediaPath = `/${props.recItem.mediaB.type}/` + props.recItem.mediaB.mediaID;

    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <Cover  mediaItem = {props.recItem.mediaB} size={window.screen.width > 500 ? "L" : "S"} loc={"bookRec:"+props.recItem.recommendationID}/>
            <div className={styles.bookInfoAndNote}>
                <Link to={mediaPath} className={styles.link}><h1 className={styles.bookTitle}>{props.recItem.mediaB.title}</h1></Link>
                <Author authorsList={props.recItem.mediaB.creators} size={1} link={true}/>
                <div className={styles.note}>{props.recItem.note}</div>
            </div>
            
        </section>
    )
}