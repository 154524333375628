import React, { useEffect, useState } from 'react';
import styles from "./HomeTBR.module.css";

import { getMediaByLibrary } from '../../api/Api';

import { TBRbook } from '../BookTypes/TBRBook/TBRBook';
import { Loader } from "../Loader/Loader";

export const HomeTBR = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    const [TBRMedia, setTBRMedia] = useState([]);
    const [listOfMedia, setListOfMedia] = useState([]);

    const [infoFound, setInfoFound] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [cssModifier, setCSSModifier] = useState(null);

    let token = localStorage.getItem("token");

    useEffect(() => { // gets books and pages on first render
        fetchMedia()
    }, []);

    useEffect(() => { // makes books once all data is found
        if (TBRMedia.length > 0) { // only make display if there are TBR books
            getRandomDisplay();
        }
    },[infoFound]) 


    // ----------------------------------------------------- PRE-RENDER

    async function fetchMedia() {
        // gets books from back

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        await getMediaByLibrary("TBR", token)
            .then((foundMedia) => {
                if (foundMedia.length === 0) {
                    setListOfMedia(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
                } else {
                    setTBRMedia(foundMedia);
                }
                setInfoFound(true);
            })
            .catch((error) => {
                console.log("failed to get TBR media", error)
                setIsLoading(false)
            })
    }

    function getRandomDisplay() {
        // gets random indexes and corrosponding books
        // gets number of books to show, 3 if 0 or 1 currently reading 3*n otherwise

        // number of media to show
        var numBooks = window.innerWidth > 500 ? Math.floor(window.innerWidth / 188) : Math.floor(window.innerWidth/150); // number of books across based on window size

        let tempCSS = "1fr ";
        setCSSModifier(tempCSS.repeat(numBooks));

        // checks that there are enough books
        if (TBRMedia.length <= numBooks) {
            makeMediaItems(TBRMedia);
            return;
        }

        // makes random display
        var indexes = [];
        var tempDisplay = []

        //gets number of random indexes for each currenly reading book
        while (indexes.length < numBooks) {
            var r = Math.floor(Math.random() * TBRMedia.length - 1) + 1;
            if (indexes.indexOf(r) === -1) { // if not already picked
                indexes.push(r);
                tempDisplay.push(TBRMedia[r]);
            }
        }
        makeMediaItems(tempDisplay); 
    }

    function makeMediaItems(foundMedia) {
        //makes list of books

        if (foundMedia.length > 0) {
            let tempList = []
            for (let i = 0; i < foundMedia.length; i++) {
                tempList.push(
                    <div key={foundMedia[i].mediaID}><TBRbook mediaItem={foundMedia[i]} /></div>
                )
            }
            setListOfMedia(tempList);
        } else if (infoFound === true) {
            setListOfMedia(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
        }

        setIsLoading(false)
    }

    // ----------------------------------------------------- RENDER
    return (
        <div className={styles.container}>
            {isLoading ?
                <div className={styles.loader}>
                    <Loader />
                </div>
                :
                <div className={styles.content}  style={{gridTemplateColumns: cssModifier}}>
                    {listOfMedia}
                </div>
            }
        </div>

    );

}