import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./TagSelector.module.css";

import { getTag, getTags, deleteTag, getTagsWithAttachedMedia } from "../../api/Api.js";

import { Tag } from "../Tag/Tag.js";
import { TagHeader } from "../Tag/TagHeader.js";

import { TagBook } from "../BookTypes/TagBook/TagBook.js";

import { Loader } from "../Loader/Loader.js";
import { useMediaUpdate } from "../../contexts/MediaContext.js";

export const TagSelector = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    let selectedTagID = useLocation().pathname.split("/")[2];
    let resetPath = "/tags";

    const bookUpdater = useMediaUpdate();

    const [allTagHeaderObjects, setAllTagHeaderObjects] = useState([]);
    const [allTagMediaObject, setAllTagMediaObjects] = useState(null);

    const [allTagsList, setAllTagsList] = useState();
    const [allTagsNameDict, setAllTagsNameDict] = useState({});
    const [allTagsDict, setAllTagsDict] = useState(null);

    const [changeOccured, setChangeOccured] = useState(null);

    useEffect(() => {
        // first render, get tags
        fetchTags();
        if (selectedTagID !== undefined) fetchTag(selectedTagID);
        fetchAttachedMedia(); // background task to process all tags

        // resets contexts to avoid flash of previous book/tvshow
        bookUpdater({});
    }, []);

    useEffect(() => {
        // runs when selected tag changes
        setAllTagMediaObjects(null);

        if (allTagsDict && selectedTagID !== undefined) {
            makeTagHeaderObjects(allTagsList);

            if (allTagsDict[selectedTagID] === null) {
                fetchTag(selectedTagID);
            }
            else {
                makeTagMediaObjects(allTagsDict[selectedTagID]);
            }

        } 
    }, [selectedTagID]);

    useEffect(() => {
        // runs when selected tag changes AND when avalible data changes
        if (allTagsDict && selectedTagID !== null) { 
            // if data for selected tag is null, means it is still loading
            if (allTagsDict[selectedTagID] === null) setAllTagMediaObjects(null);
            else makeTagMediaObjects(allTagsDict[selectedTagID]);
        }
    },[selectedTagID, allTagsDict, changeOccured])


    // ----------------------------------------------------- PRE-RENDER

    async function fetchTags() {
        // gets all tags WITHOUT associated books and tvshows for speed
        await getTags(token)
            .then((tags) => {
                setAllTagsList(tags);

                // turns tags and tag names into easily traverable dictionary
                let temp = {}
                let tempNames = {}
                for (let i = 0; i < tags.length; i++) {
                    temp[tags[i].tagID] = null;
                    tempNames[tags[i].tagID] = tags[i].tagValue;
                }

                setAllTagsDict(temp);
                setAllTagsNameDict(tempNames);

                makeTagHeaderObjects(tags);
            })
            .catch((error) => {
                console.log(error, "failed to fetch tags");
            })
    }

    async function fetchTag(tagToFetch) {
        // gets an individual tagItem
        await getTag(tagToFetch, token)
            .then((tag) => {
                // saves found data to dict if dict exists
                if (allTagsDict) {
                    // tag dictionary already set up
                    let temp = allTagsDict;
                    temp[tag.tagID] = tag;
                    setAllTagsDict(temp);

                    setChangeOccured(tag.tagID);
                } else {
                    // no tag dictionary to add tag to. 
                    // Should ONLY occur when tag page is navigated to through a tag or is refreshed with tag already selected
                    makeTagMediaObjects(tag);
                }
            })
            .catch((error) => {
                console.log(error, "failed to get ", selectedTagID);
            })
    }

    async function fetchAttachedMedia() {
        // gets all tags WITH asscociated media, processes in background
        await getTagsWithAttachedMedia(token)
            .then((tags) => {
                // adds all tag data to dict
                let temp = {}
                for (let i = 0; i < tags.length; i++) {
                    temp[tags[i].tagID] = tags[i];
                }
                setAllTagsDict(temp);
            })
            .catch((error) => {
                console.log(error, "failed to get all tags with attached media");
            })
    }

    async function deleteTagFromDB() {
        // deletes tag from database

        await deleteTag(selectedTagID, token)
            .then(() => {
                fetchTags();
            })
            .catch((error) => {
                console.log(error, "failed to delete tag");
            });
    }

    function handleDeleteTag() {
        // makes call to backend  
        deleteTagFromDB()         
    }

    function makeTagHeaderObjects(tags) {
        // makes tag objects for top of page

        if (tags?.length > 0) {
            let tempTagsList = [];
            let tempLetterList = [];
            let lastHeader = null;

            for (let i = 0; i < tags.length; i++) {
                // adds header for each letter seen to array
                if (!(/[a-zA-Z]/).test(tags[i].tagValue.slice(0, 1))) {
                    // group tags that do not start with a letter 
                    if (lastHeader !== "num") {
                        // adds tag header to letter group
                        tempLetterList.push(<div key={"num"}>
                            <TagHeader headerValue={"123!?"} />
                        </div>);
                        // updates last seen header
                        lastHeader = "num";
                    }
                }
                else if (tags[i].tagValue.slice(0, 1) != lastHeader) {
                    // groups tag by first letter
                    if (lastHeader != null) {
                        // pushes letter group to larger array if it is not the first time through the loop
                        tempTagsList.push(<div className={styles.letterGroup} key={"letterGroup:" + lastHeader}>
                            {tempLetterList}
                        </div>);
                        tempLetterList = [];
                    }

                    // adds tag header to letter group
                    tempLetterList.push(<div key={tags[i].tagValue.slice(0, 1)}>
                        <TagHeader headerValue={tags[i].tagValue.slice(0, 1).toUpperCase()} />
                    </div>);
                    // updates last seen header
                    lastHeader = tags[i].tagValue.slice(0, 1);
                }
                // adds tag to letter group
                tempLetterList.push(<div className={styles.tag} key={tags[i].tagID}>
                    <Tag tagItem={tags[i]} selected={selectedTagID} nextTag={tags[0].tagID} />
                </div>);
            }

            // adds all groups to main array
            setAllTagHeaderObjects([tempTagsList, tempLetterList]);
        } else {
            // empty message for no tags
            setAllTagHeaderObjects(<div className={styles.emptyShelf}>
                <h1>This shelf is currently empty </h1>
            </div>)
        }
    }

    function makeTagMediaObjects(tag) {
        // makes tag book/tvshow objects
        if (tag === undefined) return;

        let tempTagObjectList = [];

        // books
        if (tag.media !== null) {
            // sorts books by author and makes book obejcts
            let sortedBooks = tag.media.sort(function (a, b) {
                if (a.creators[0]?.last > b.creators[0]?.last) {
                    return 1;
                }
                if (a.creators[0]?.last < b.creators[0]?.last) {
                    return -1;
                }
                return 0;
            });

            // makes objects
            for (let i = 0; i < sortedBooks.length; i++) {
                tempTagObjectList.push(
                    <TagBook mediaItem={sortedBooks[i]} key={sortedBooks[i].mediaID} />
                )
            }
        }

        // saving
        if (tempTagObjectList?.length === 0) {
            // NO books and NO tvshows
            setAllTagMediaObjects(
                <div className={styles.emptyMessageContainer}>
                    <h4 className={styles.emptyMessage}> There are no items with this tag</h4>
                    <Link to={resetPath} ><button className={styles.removeTagButton} onClick={handleDeleteTag}>Delete Tag</button></Link>
                </div>
            )
        } else {
            // EITHER books or tvshows
            setAllTagMediaObjects(tempTagObjectList);
        }
    }


    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <h1 className={styles.sectionTitle}>Tags</h1>
            <div className={styles.content}>
                <div className={styles.tagGridContainer}>
                    {allTagHeaderObjects}
                </div>
            </div>
            {selectedTagID !== undefined ?
                <div className={styles.selectedBooksContainer}>
                    <h1 className={styles.sectionTitle}>Media tagged: {allTagsNameDict[selectedTagID]}</h1>
                    <div className={styles.content}>
                        {allTagMediaObject === null ?
                            <div className={styles.loaderForMediaTagged}>
                                <Loader />
                            </div>
                            :
                            <div className={styles.bookGridContainer}>
                                {allTagMediaObject}
                            </div>}
                    </div>
                </div> : null
            }
        </section>
    )
}